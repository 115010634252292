/**
 * The Main Custom Frontend JS. Loads in footer.
 *
 * See the header JS for getting the responsive breakpoint, in get_responsive_breakpoint().
 *
 */

/*
 * **************************************************************************************************
 * Begin Global JS
 * **************************************************************************************************
 */

/*
 * **************************************************************************************************
 * Begin Bootstrap / MDB Imports
 * **************************************************************************************************
 */

/**
 * The list of JS imports for fronend JS. If you change anything, MDB has multiple references to
 * adjust.
 *
 * This is done based on the instruction in MDB's docs of Migrating to MDB v7. There they explained
 * the difference between ES and UMD formats. We want to load things modularly so the instructions
 * to follow said to use ES. They gave an example of how to import and init. It seems redundant
 * since the same list is needed multiple times but I could not figure out how to import by looping
 * over an array or object. - 12/14/2023 JRav.
 *
 * @see https://mdbootstrap.com/docs/standard/getting-started/migration-guide/
 *
 * When using modules this way you do not use `new mdb.ModuleName`, you only use `new ModuleName`
 */
/* eslint-disable no-unused-vars */
import {
	// BOOTSTRAP CORE COMPONENTS
	Button,
	Offcanvas,
	Carousel,
	// Popover,
	// Scrollspy,
	Tab,
	Tooltip,

	// MDB FREE COMPONENTS
	Input,
	Collapse,
	Dropdown,
	Ripple,
	// Range,

	// MDB PRO COMPONENTS
	Animate,
	// Lightbox,
	// Rating,
	Sidenav,
	Alert,
	// Toast,
	// Timepicker,
	Navbar,
	// InfiniteScroll,
	// LazyLoad,
	// Datepicker,
	// Popconfirm,
	// Datatable,
	// Stepper,
	// Sticky,
	Select,
	// Touch,
	// SmoothScroll,
	PerfectScrollbar,
	Loading,
	Autocomplete,
	Modal,
	Clipboard,
	ChipsInput,
	Chip,
	// MultiRangeSlider,
	// Datetimepicker,

	// Auto init
	initMDB,
} from 'mdbootstrap-pro/js/mdb.es.min.js';

window.Button = Button;
window.Offcanvas = Offcanvas;
window.Carousel = Carousel;
// window.Popover = Popover;
// window.Scrollspy = Scrollspy;
window.Tab = Tab;
window.Tooltip = Tooltip;
window.Input = Input;
window.Collapse = Collapse;
window.Dropdown = Dropdown;
window.Ripple = Ripple;
// window.Range = Range;
window.Animate = Animate;
// window.Lightbox = Lightbox;
// window.Rating =  Rating;
window.Sidenav = Sidenav;
window.Alert = Alert;
// window.Toast = Toast;
// window.Timepicker = Timepicker;
window.Navbar = Navbar;
// window.InfiniteScroll = InfiniteScroll;
// window.LazyLoad = LazyLoad;
// window.Datepicker = Datepicker;
// window.Popconfirm = Popconfirm;
// window.Datatable = Datatable;
// window.Stepper = Stepper;
// window.Sticky = Sticky;
window.Select = Select;
// window.Touch = Touch;
// window.SmoothScroll = SmoothScroll;
window.PerfectScrollbar = PerfectScrollbar;
window.Loading = Loading;
window.Autocomplete = Autocomplete;
window.Modal = Modal;
window.Clipboard = Clipboard;
window.ChipsInput = ChipsInput;
window.Chip = Chip;
// window.MultiRangeSlider = MultiRangeSlider;
// window.Datetimepicker = Datetimepicker;

initMDB({
	Button,
	Offcanvas,
	Carousel,
	// Popover,
	// Scrollspy,
	Tab,
	Tooltip,
	Input,
	Collapse,
	Dropdown,
	Ripple,
	// Range,
	Animate,
	// Lightbox,
	// Rating,
	Sidenav,
	Alert,
	// Toast,
	// Timepicker,
	Navbar,
	// InfiniteScroll,
	// LazyLoad,
	// Datepicker,
	// Popconfirm,
	// Datatable,
	// Stepper,
	// Sticky,
	Select,
	// Touch,
	// SmoothScroll,
	PerfectScrollbar,
	Loading,
	Autocomplete,
	Modal,
	Clipboard,
	ChipsInput,
	Chip,
	// MultiRangeSlider,
	// Datetimepicker,
});

/*
 * **************************************************************************************************
 * End Bootstrap / MDB Imports
 * **************************************************************************************************
 */

/*
 * **************************************************************************************************
 * Begin init / enable BigPicture lightbox.
 * **************************************************************************************************
 */

// Importing will cause the node_modules JS to be pulled in to this frontend script.
import BigPicture from 'bigpicture';

/**
 * Galleries
 */
const galleries = document.querySelectorAll(
	'.bsu-body-content .gallery:not(.bsu-no-lightbox)'
);

for (let x = 0; x < galleries.length; x++) {
	const galleryId = galleries[x].id;

	galleries[x].addEventListener('click', function (ev) {
		/**
		 * We cannot just provide the wrapper since the IMGs are nested in other elements. We
		 * need to key in on the exact images.
		 */
		const galleryItems = document.querySelectorAll(
			'#' + galleryId + ' .' + 'in-' + galleryId
		);

		ev.preventDefault(); // Don't open links.

		// jshint -W117
		// Ignore jshint W117 because BigPicture is set in a script compiled in the build process.
		BigPicture({
			el: ev.target,
			gallery: galleryItems,
			galleryAttribute: 'data-bp',
		});
		// jshint +W117
	});
}

/**
 * Individual images. Only in post/page content. Only images with a HREF to an image will get
 * lightbox treatement. This will allow images to be used as links to other content.
 */
const indivImg = document.querySelectorAll(
	'.bsu-body-content .img-fluid:not(.bsu-no-lightbox), .bsu-body-content .img-thumbnail:not(.bsu-no-lightbox)'
);

for (const img of indivImg) {
	const galleryWrapper = img.closest('.gallery');
	const isIndivImg = !galleryWrapper;

	if (isIndivImg) {
		img.style.cursor = 'pointer';

		img.addEventListener('click', function (event) {
			const img = event.target;

			/**
			 * Attempt to get the full size, original image. BigPicture will use the img src
			 * attribute if the imgSrc is null or not provided on individual images.
			 */
			const fullSizeImg = img.getAttribute('data-bp');

			/**
			 * Check if the HREF is to the original image and determine if lightbox should be
			 * applied. If the link is not to the original image, do not use lightbox.
			 */
			if (img.nodeName === 'IMG' && img.parentNode.nodeName === 'A') {
				const a = img.parentNode;
				const imgFilenameAndExt = fullSizeImg.split('/').pop();
				const imgFileExt = '.' + imgFilenameAndExt.split('.').pop();
				const imgFilename = imgFilenameAndExt.replace(imgFileExt, '');
				const aFilename = a.href.split('/').pop();

				/**
				 * If these do not match then the link is not to the original image. WordPress
				 * can possibly add -scaled at the end of the filename so we factor that in
				 * with a regex match.
				 */
				const pattern = new RegExp(
					imgFilename + '(-scaled)?(\\' + imgFileExt + ')',
					'g'
				);
				const aIsToImgFile = pattern.test(aFilename);

				if (aIsToImgFile) {
					event.preventDefault();
				}
			}

			BigPicture({
				el: img,
				imgSrc: fullSizeImg,
			});
		});
	}
}

/*
 * **************************************************************************************************
 * End init / enable BigPicture lightbox.
 * **************************************************************************************************
 */

/*
 * **************************************************************************************************
 * Begin add compressed class to the #bsu-branding-main-nav header when scrolling.
 * **************************************************************************************************
 */
const bsuBrandingMainNav = document.getElementById('bsu-branding-main-nav');

if (!bsuBrandingMainNav.classList.contains('static')) {
	let lastKnownScrollPosition = 0;
	let scrolling = false;

	document.addEventListener('scroll', () => {
		lastKnownScrollPosition = window.scrollY;

		if (!scrolling) {
			window.requestAnimationFrame(() => {
				if (lastKnownScrollPosition >= 60) {
					bsuBrandingMainNav.classList.add('compressed');
					bsuBrandingMainNav.classList.remove('bg-opacity-75');
				} else {
					bsuBrandingMainNav.classList.remove('compressed');
					bsuBrandingMainNav.classList.add('bg-opacity-75');
				}

				scrolling = false;
			});

			scrolling = true;
		}
	});
}
/*
 * **************************************************************************************************
 * End add compressed class to the #bsu-branding-main-nav header when scrolling.
 * **************************************************************************************************
 */

/*
 * **************************************************************************************************
 * Begin Footer Parallax
 * **************************************************************************************************
 */

/**
 * It seem like this should be effected by something like the Dichotomy paradox since the
 * moving element is moving and we are looking its position... but it just works so I am
 * leaving it here with more comments than last. -- JRav
 */

const bsuFooterParallaxMove = function () {
	// The element to look for.
	const parallaxBack = document.getElementById('bsu-footer-parallax-back');

	// Determines how much parallax movement there is.
	const parallaxStart = 11;

	// Get the element's top and bottom position within the window.
	const movingElementTop =
		parallaxBack.getBoundingClientRect().top + window.scrollY;
	const movingElementBottom =
		movingElementTop + parallaxBack.getBoundingClientRect().height;

	// Get the current top and bottom positions of the viewport.
	const viewportTop = window.scrollY;
	const viewportBottom = viewportTop + window.innerHeight;

	// Check viewport position and element position to determine if the element is in view.
	const inViewport =
		movingElementBottom > viewportTop && movingElementTop < viewportBottom;

	// Init these vars so they can be set by calculations.
	let percentageWithin;
	let newPercentBottom;

	if (true === inViewport) {
		// Calculate the percentage of the viewportBottom that is within the moving parallax element.
		percentageWithin =
			(viewportBottom - movingElementTop) /
			(movingElementBottom - movingElementTop);

		// Calculate the new margin-bottom of the moving parallax element.
		newPercentBottom = parallaxStart / percentageWithin;

		// Set the margin-bottom of the moving parallax element.
		parallaxBack.style.marginBottom = newPercentBottom + 'rem';
	}
};

// Runs on load. Calculate the parallax in case we are on a short page.
bsuFooterParallaxMove();

// Calculate all positions on scroll.
document.addEventListener('scroll', bsuFooterParallaxMove);

/*
 * **************************************************************************************************
 * End Footer Parallax
 * **************************************************************************************************
 */

/*
 * **************************************************************************************************
 * End Global JS
 * **************************************************************************************************
 */

/*
 * **************************************************************************************************
 * Begin Homepage JS
 * **************************************************************************************************
 */

/*
 * **************************************************************************************************
 * Begin add CSS classes to the .bsu-cta-homepage call to action buttons when scrolling
 * **************************************************************************************************
 */
// Check the ready state otherwise the height of carousel may be calculated at 0.
document.onreadystatechange = function () {
	if (document.readyState === 'complete') {
		const ctaHomepageWrapper = document.querySelector(
			'div.bsu-cta-homepage'
		);

		const ctaHomepageList = document.querySelector(
			'.bsu-cta-nav .navbar ul'
		);

		const ctaHomepageButtons = document.querySelectorAll(
			'a.bsu-cta-homepage-button'
		);

		const heroCarousel = document.getElementById('hero-carousel');

		/**
		 * The classes variables below are named for classes that are added when the element is
		 * in a specific location. These class lists are added and removed depending on where they
		 * are being moved to during the scroll event.
		 */

		const ctaWrapperClassesInHeader = 'bg-secondary';

		const ctaWrapperClassesDefault = 'mt-lg-n5';

		const ctaUlClassesDefault = 'gap-lg-2';

		// Classes to add/remove from individual links (A tags) used as CTA buttons.
		const ctaLinkClassesDefault = ['btn', 'btn-lg', 'btn-primary'];

		// Check if the ctaHomepageWrapper element was found.
		if (ctaHomepageWrapper) {
			const bsuFixedTop = document.querySelector('.fixed-top');

			// The scroll height of when to move the CTA buttons.
			const bsuCtaMove =
				heroCarousel.offsetTop +
				heroCarousel.offsetHeight -
				bsuFixedTop.offsetHeight +
				ctaHomepageButtons[0].offsetHeight / 2;

			// The scroll height of when to
			const bsuCtaMoveOff =
				heroCarousel.offsetTop +
				heroCarousel.offsetHeight -
				bsuFixedTop.offsetHeight;

			let lastKnownScrollPosition = 0;
			let scrolling = false;

			document.addEventListener('scroll', () => {
				lastKnownScrollPosition = window.scrollY;

				if (false === scrolling) {
					window.requestAnimationFrame(() => {
						if (lastKnownScrollPosition > bsuCtaMove) {
							/**
							 * The viewport is scrolled below the CTA buttons. Put them on the header.
							 */

							if (
								!ctaHomepageWrapper.classList.contains(
									'bg-secondary'
								)
							) {
								/**
								 * When .bg-secondary is found, that means .bsu-cta-homepage is fixed to the top of the page.
								 */

								// Put the CTA container in to the fixed top/nav container.
								bsuFixedTop.append(ctaHomepageWrapper);
							}

							for (const aTag of ctaHomepageButtons) {
								// Add each individual class in the array.
								for (const individualClass of ctaLinkClassesDefault) {
									aTag.classList.remove(individualClass);
								}
							}

							ctaHomepageList.classList.remove(
								ctaUlClassesDefault
							);
							ctaHomepageWrapper.classList.remove(
								ctaWrapperClassesDefault
							);
							ctaHomepageWrapper.classList.add(
								ctaWrapperClassesInHeader
							);
						} else if (
							lastKnownScrollPosition < bsuCtaMoveOff &&
							ctaHomepageWrapper.classList.contains(
								'bg-secondary'
							)
						) {
							/**
							 * The viewport is scrolled above the CTA buttons. Put them back on the carousel.
							 */

							// Put the CTA container below the carousel.
							heroCarousel.parentNode.insertBefore(
								ctaHomepageWrapper,
								heroCarousel.nextSibling
							);

							for (const aTag of ctaHomepageButtons) {
								// Remove each individual class in the array.
								for (const individualClass of ctaLinkClassesDefault) {
									aTag.classList.add(individualClass);
								}
							}

							ctaHomepageList.classList.add(ctaUlClassesDefault);
							ctaHomepageWrapper.classList.remove(
								ctaWrapperClassesInHeader
							);
							ctaHomepageWrapper.classList.add(
								ctaWrapperClassesDefault
							);
						}

						scrolling = false;
					});

					scrolling = true;
				}
			});
		}
	}
};
/*
 * **************************************************************************************************
 * End add CSS classes to the .bsu-cta-homepage call to action buttons when scrolling
 * **************************************************************************************************
 */

/*
 * **************************************************************************************************
 * Begin functionality for back to floating action buttons (FABs) page top button and chat.
 * **************************************************************************************************
 */

/**
 * NOTE on COMPROMISE: Best practice is that there is only one FAB. The FAB for back to top is at
 * the bottom right which is also best practice. Since chat appears as a FAB, we are making it hide
 * after a user scrolls past one viewport height. The script for distance is in chat-distance-mn.js.
 */

// JS is required for this feature. It is initially written to the page by JS.
const viewportHeight = window.innerHeight;
const docBodyHeight = document.body.scrollHeight;
const threasholdBackToTop = viewportHeight * 4;

// Only create the back to top button if the body is large enough to make use of it.
if (docBodyHeight >= threasholdBackToTop) {
	const backToTopButton = document.createElement('a');
	backToTopButton.id = 'bsu-back-to-top';
	backToTopButton.setAttribute('title', 'Back to Top');
	backToTopButton.setAttribute('aria-label', 'Back to Top');
	backToTopButton.innerHTML = '<i class="fas fa-chevron-up"></i>';
	backToTopButton.classList.add(
		'btn',
		'btn-primary',
		'btn-floating',
		'position-fixed',
		'bottom-0',
		'end-0',
		'm-4',
		'd-none'
	);
	backToTopButton.onclick = function () {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	// Place the button in the <body> after it has been setup above.
	document.body.appendChild(backToTopButton);

	// Use MDB's JS API for showing the button.
	const backToTopShow = new Animate(backToTopButton, {
		animation: 'fade-in-up',
		animationStart: 'manually',
		animationRepeat: false,
		onStart: () => {
			backToTopButton.classList.remove('d-none', 'fade-out-down');
		},
	});
	backToTopShow.init();

	// Use MDB's JS API for hiding the button.
	const backToTopHide = new Animate(backToTopButton, {
		animation: 'fade-out-down',
		animationStart: 'manually',
		animationRepeat: false,
		onStart: () => {
			backToTopButton.classList.remove('fade-in-up');
		},
	});
	backToTopHide.init();

	// Flag if the scroll amount is past the configured threshold.
	let scrollAmountMetForBackToTop = false;

	document.addEventListener('scroll', () => {
		// Check how far down the page the user has scrolled.
		const scrollAmount = window.scrollY;

		// Determine if scroll amount is enough to show the Back to Top button.
		if (
			scrollAmount >= threasholdBackToTop &&
			false === scrollAmountMetForBackToTop
		) {
			backToTopShow.startAnimation();
			scrollAmountMetForBackToTop = true;
		} else if (
			scrollAmount < threasholdBackToTop &&
			true === scrollAmountMetForBackToTop
		) {
			backToTopHide.startAnimation();
			scrollAmountMetForBackToTop = false;
		}
	});
}

/*
 * **************************************************************************************************
 * End functionality for page top link.
 * **************************************************************************************************
 */
